/*
 * @Author: yang shanshan
 * @Date: 2022-9-5 15:11
 * @Last Modified by: yang shanshan
 * @Last Modified time: 2024-3-27 15:11
 */
import axios from "axios";
import projectConfig from "../../src/config/projectConfig.js";
import store from "../store";
import router from "@/router";
/****** 创建axios实例 ******/
const service = axios.create({
  baseURL: projectConfig.baseURL,
  // baseURL: "http://5002mb2526.zicp.fun/",
  timeout: 300000, // 请求超时时间--5分钟
});

/****** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(
  (config) => {
    if (
      !config.url.includes("loginx") &&
      !config.url.includes("captchaImage") &&
      !config.url.includes("loginByPhone") &&
      !config.url.includes("sendMessageCode") &&
      !config.url.includes("checkMessageCode") &&
      !config.url.includes("registerByPhone") &&
      !config.url.includes("login") &&
      !config.url.includes("homePageMenu")
    ) {
      // 给请求头部加上token
      let token = store.state.system.token;
      if (token !== null && token !== "null") {
        config.headers["Authorization"] = token;
      } else {
        router.push({ path: "/login" });
      }
    }
    return config;
  },
  (error) => {
    //请求错误处理
    Promise.reject(error);
  }
);

/****** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      router.push({ path: "/login" });
      return response.data;
    } else {
      return response.data;
    }
  },
  (error) => {
    //响应错误处理
    return Promise.reject(error);
  }
);
export default service;
