import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "@/store";
import { getCurrentUser } from "@/api/login";
Vue.use(VueRouter);
// 解决NavigationDuplicated: Avoided redundant navigation to current location: 问题
// 缓存原型上的push函数
const originPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;
// 给原型对象上的push指定新函数函数
VueRouter.prototype.push = function (location, onComplete, onAbort) {
  // 判断如果没有指定回调函数, 通过call调用源函数并使用catch来处理错误
  if (onComplete === undefined && onAbort === undefined) {
    return originPush.call(this, location, onComplete, onAbort).catch(() => {});
  } else {
    // 如果有指定任意回调函数, 通过call调用源push函数处理
    originPush.call(this, location, onComplete, onAbort);
  }
};

// replace函数
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (onComplete === undefined && onAbort === undefined) {
    return originReplace
      .call(this, location, onComplete, onAbort)
      .catch(() => {});
  } else {
    originReplace.call(this, location, onComplete, onAbort);
  }
};

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
      requiresAuth: false,
    },
    component: () => import("../views/pc/home.vue"),
    redirect: "/index",
    children: [
      {
        path: "/login",
        name: "login",
        meta: {
          title: "登录",
          requiresAuth: false,
        },
        component: () => import("../views/pc/login/login.vue"),
      },
      {
        path: "/register",
        name: "register",
        meta: {
          title: "注册",
          requiresAuth: false,
        },
        component: () => import("../views/pc/register/register.vue"),
      },
      {
        path: "index",
        name: "index",
        meta: {
          title: "index",
          requiresAuth: false,
        },
        component: () => import("../views/pc/index/index.vue"),
      },
      {
        path: "userguide",
        name: "userguide",
        meta: {
          title: "userguide",
          requiresAuth: false,
        },
        component: () => import("../views/pc/userguide/userguide.vue"),
      },
      {
        path: "aboutUS",
        name: "aboutUS",
        meta: {
          title: "aboutUS",
          requiresAuth: false,
        },
        component: () => import("../views/pc/aboutUS/aboutUS.vue"),
        redirect: "aboutUS/introduce",
        children: [
          {
            path: "introduce",
            name: "introduce",
            meta: {
              title: "introduce",
              requiresAuth: false,
            },
            component: () => import("../components/pc/aboutUS/introduce.vue"),
          },
          {
            path: "contactUS",
            name: "contactUS",
            meta: {
              title: "contactUS",
              requiresAuth: false,
            },
            component: () => import("../components/pc/aboutUS/contactus.vue"),
          },
        ],
      },
      {
        path: "product",
        name: "product",
        meta: {
          title: "product",
          requiresAuth: false,
        },
        component: () => import("../views/pc/product/product.vue"),
        redirect: "product/insar",
        children: [
          {
            path: "insar",
            name: "insar",
            meta: {
              title: "insar",
              requiresAuth: false,
            },
            component: () => import("../components/pc/product/insar.vue"),
          },
          {
            path: "house",
            name: "house",
            meta: {
              title: "house",
              requiresAuth: false,
            },
            component: () => import("../components/pc/product/house.vue"),
          },
        ],
      },
      {
        path: "usermanager",
        name: "usermanager",
        meta: {
          title: "usermanager",
          requiresAuth: false,
        },
        component: () => import("../views/pc/usermanager/user.vue"),
      },
      {
        path: "/accountBinding",
        name: "accountBinding",
        meta: {
          title: "账号绑定微信",
          requiresAuth: false,
        },
        component: () => import("../views/pc/accountBinding/index.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    meta: {
      title: "error",
      requiresAuth: false,
    },
    component: () => import("../views/error.vue"),
  },
  {
    path: "/protocol",
    name: "protocol",
    meta: {
      title: "protocol",
      requiresAuth: false,
    },
    component: () => import("../views/protocol.vue"),
  },
  {
    path: "/wxCode",
    name: "wxCode",
    meta: {
      title: "wxCode",
      requiresAuth: false,
    },
    component: () => import("../views/wxCode.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach(checkAuth)
function checkAuth(to, from, next) {
  if (to.path.includes("wxCode")) {
    if (to.fullPath.indexOf("token") >= 0) {
      let token = getQueryVariable("token") || getPathVariable(to.fullPath, "token");
      handleStoreToken(token);
      getCurrentUser()
          .then((res) => {
            if (res.code === 200) {
              let data = res.data;
              handleStore(data);
              let userLogin = JSON.stringify({ phone: data.phone });
              localStorage.setItem("userLogin", userLogin);
              next({ path: "/" });
            } else {
              next({ path: "/login" });
              handleMessaage(this.$t("L_LOGIN.LOGIN_0007"), this.$t("L_LOGIN.LOGIN_0007"));
            }
          })
          .catch(() => {
            next({ path: "/login" });
            handleMessaage(this.$t("L_LOGIN.LOGIN_0007"), this.$t("L_LOGIN.LOGIN_0007"));
          });
    } else if (to.fullPath.indexOf("openId") >= 0) {
      let openId = getQueryVariable("openId") || getPathVariable(to.fullPath, "openId");
      let data = {
        openId: openId
      }
      next({ name: 'accountBinding', params: { data: data }, replace: true }); // replace 选项可以防止导航出现在历史记录中
    }
  } else {
    next();
  }
}
//获取网址param参数
function getQueryVariable(variable) {
  const w = location.hash.indexOf("?");
  const query = location.hash.substring(w + 1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}
//获取fullpath-param参数
function getPathVariable(path, variable) {
  const w = path.indexOf("?");
  const query = path.substring(w + 1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}
function handleMessaage(text1, text2) {
  this.$message({
    message: this.language === "en" ? text1 : text2,
    type: "error",
    offset: 60,
  });
}
function handleStoreToken(token) {
  store.commit("setToken", token);
}
function handleStore(info) {
  store.commit("setUserInfo", info);
}
export default router;
