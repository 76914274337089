const en = {
  L_BASIC: {
    TITLE: "Vastitude Cloud",
    DESCRIPTION:
      "Vastitude Cloud service is used to manage, deliver, and visualize InSAR monitoring data results, providing analytical services for engineering facilities, enabling rapid identification of risks, Analysis and prevention on buildings, subways, roads, high-speed rail, electricity, water, energy, insurance, etc.",
    KEYWORD:
      "Vastitude, Remote Sensing, Image Services, InSAR Monitoring, InSAR, Vastitude Cloud, Larkview, InSAR Cloud Platform, Building Deformation Analysis, InSAR Database Services",
    NAV_0001: "Home",
    NAV_0002: "Products",
    NAV_0002_01: "InSAR Service",
    NAV_0002_02: "Building Analysis",
    NAV_0002_02_01: "Remote Sensing Emergency Platform",
    NAV_0003_03: "",
    NAV_0004_04: "",
    NAV_0003: "Guide",
    NAV_0004: "About Us",
    NAV_0004_01: "Company",
    NAV_0004_02: "Contact Us",
    NAV_0005: "Sign Up",
    NAV_0006: "Log In",
    NAV_0007: "Start Now",
    NAV_0008: "You have no access rights！",
    NAV_0009: "Basic Info",
    NAV_0010: "Change Password",
    NAV_0011: "Log Out",
    NAV_0012: "Subscribe",
    FOOTER_0001: "Vastitude Website",
    FOOTER_0002: "About Us",
    FOOTER_0003: "Contact Us",
    FOOTER_0004:
      "© 2014- 2021  larkview.cn Beijing Vatitude Technology Co.,Ltd All right reserved",
    FOOTER_0005: "京ICP备18027436号-1",
    FOOTER_0006: "Vastitude Clouds",
    FOOTER_0007: "Phone number：400 699 1223, +86-10-88511338",
    FOOTER_0008: "e-mail：support@vastitude.cn",
    FOOTER_0009: "Focus on Vastitude",
    END_0001: "We Secure Tomorrow",
  },
  L_MAIN: {
    TITLE: "Home",
    TITLE_002: "Product Center",
    BANNER_0000: "",
    BANNER_0001: "EO Big Data Service",
    BANNER_0002: "Data changes decision making",
    BANNER_0003: "Abundant Database in Major Cities",
    BANNER_0004: "",
    BANNER_0005: "Fast Speed for Processing",
    BANNER_0006: "",
    BANNER_0007: "Optimal Algorithm All over the World",
    BANNER_0008: "",
    BANNER_0009: "Economic Service for Our Customer",
    BANNER_0010: "",
    BUTTON_0001: "Learn More",
    BUTTON_0002: "Contact Us",
    BUTTON_0003: "Try Now",
    BUTTON_0004: "Start Now",
    BUTTON_0005: "no permission",
    BUTTON_0006:
      "You do not have rights to access. For permission, please contact administrator！",
    PRODUCT_0001: "InSAR Database Service",
    PRODUCT_0002: "InSAR data results online browsing and deformation analysis",
    PRODUCT_0003: "Remote Sensing Emergency Platform",
    PRODUCT_0003_01: "RS Emergency Platform",
    PRODUCT_0004:
      "Building deformation risk assessment based on InSAR Technology",
    PRODUCT_0004_01:
      "3S technology-based air-space-ground integrated big data emergency management platform",
    PRODUCT_0005: "Archive Data",
    PRODUCT_0006:
      "Abundant SAR archive data provides the basis for risk assessment",
    PRODUCT_0007: "Possess the world's top satellite resources",
    PRODUCT_0008: "High frequency monitoring:image revisited in 4 days.",
    PRODUCT_0009: "Rich satellite data accumulation",
    PRODUCT_0010: "Key Area Monitoring",
    PRODUCT_0011:
      "Targeted monitoring of important engineering facilities or area of customers concern",
    PRODUCT_0012: "Get data from each area, industry and field",
    PRODUCT_0013: "Acquire data in areas where manpower are difficult to reach",
    PRODUCT_0014:
      "Support field investigations, make decisions, solve problems",
    PRODUCT_0015: "Data Analysis",
    PRODUCT_0016: "InSAR data analysis results visualization",
    PRODUCT_0017: "InSAR data results online browsing and analysis",
    PRODUCT_0018:
      "Online identification of deformation risk of engineering facilities",
    PRODUCT_0019: "Building deformation analysis and risk clarification",
    PRODUCT_0020:
      "Hidden trouble recognizing and pre-warn of geological disasters",
    PRODUCT_0021:
      "Hidden trouble recognizing and pre-warn of geological disasters based on InSAR technology",
    PRODUCT_0022:
      "Intelligent investigation system for deformation risk of urban buildings",
    PRODUCT_0023:
      "Intelligent investigation system for deformation risk of urban buildings based on InSAR technology",
    PRODUCT_0031: "矿区空天地孔协同监测平台",
    PRODUCT_0031_01: "综合应用遥感、物联网、地面监测等技术对矿区进行协同监测",
    PRODUCT_0032: "风险普查成果可视化系统",
    PRODUCT_0032_01: "针对全国第一次风险普查成果设计开发的可视化系统",
    PRODUCT_0041: "天空地一体化建筑监测平台",
    PRODUCT_0041_01: "综合应用遥感、物联网等技术对建筑进行协同监测",
    PRODUCT_0042: "全国InSAR形变一张图",
    PRODUCT_0042_01: "",
    PRODUCT_0051: "综合遥感监测云服务平台",
    PRODUCT_0051_01: "应用光学遥感、雷达遥感进行协同监测",
    PRODUCT_0052: "专题事件集",
    PRODUCT_0052_01: "针对事件进行专题分析",
    PRODUCT_0061: "矿山采空区沉降AI智能识别与动态监测",
    PRODUCT_0061_01: "",
    PRODUCT_0062: "中小型水库安全监测系统",
    PRODUCT_0062_01: "",
    PRODUCT_0071: "建筑风险快速识别与监测系统",
    PRODUCT_0071_01: "基于InSAR和传感器等多源数据实现建筑风险研判管控",
    PRODUCT_0072: "一带一路国家地表形变一张图",
    PRODUCT_0072_01: "全球地表形变之一带一路国家地表形变一张图",
    PRODUCT_0081: "数字农场管理平台",
    PRODUCT_0081_01: "",
    PRODUCT_0082: "耕地态势监测系统",
    PRODUCT_0082_01: "",
    PRODUCT_0083: "海南雷达信息知识产品一张图",
    PRODUCT_0083_01: "",
    PRODUCT_0084: "广东雷达信息知识产品一张图",
    PRODUCT_0084_01: "",
    PRODUCT_0085: "雄安雷达信息知识产品一张图",
    PRODUCT_0085_01: "",
    PRODUCT_0086: "烟台市空天信息一体化平台",
    PRODUCT_0086_01: "",
    KIND_0001: "City",
    KIND_0002: "",
    KIND_0003:
      "Identifying ground movement and displacement in unstable urban areas, providing monitoring support for risk prevention, construction and operation of underground space projects in advance",
    KIND_0004: "Transportation",
    KIND_0005: "",
    KIND_0006:
      "To identify regional risks along the entire life cycle of transportation facilities such as high-speed railways, highways and urban subway",
    KIND_0007: "Insurance",
    KIND_0008: "",
    KIND_0009:
      "Provides risk assessment based on cloud services, reduces the difficulty of application and maintenance, assists claims settlement",
    KIND_0010: "Building",
    KIND_0011: "",
    KIND_0012:
      "Evaluate the health of the building, grasp the deformation around the building, quickly identify the problem area, assist in the ground survey",
    KIND_0013: "Energy",
    KIND_0014: "",
    KIND_0015:
      " By analyzing the characteristics of spatial distribution and time series of subsidence area, it provides a scientific basis for the safety and maintenance of energy facilities and guarantees their safe operation",
    SERVICE_0001: "Service Mode",
    SERVICE_0002: "Diversified services meet different needs",
    SERVICE_0003: "Standardized Products",
    SERVICE_0004:
      "Provide standardized InSAR data results display and analysis services to meet user basic needs",
    SERVICE_0005: "Customized Products",
    SERVICE_0006:
      "Support flexible deployment of public, private and hybrid clouds, and provide API interfaces to meet user different needs",
    SERVICE_0007: "Data Consulting Service",
    SERVICE_0008:
      "Provide professional data consulting services by combining big data with artificial intelligence and city / industry computing",
    PARTNER_0001: "Partner",
    PARTNER_0002: "Co-constructing sharing enabling",
    VIDEO_0001: "Watch Our Video Tutorial",
    VIDEO_0002: "Brief Introduction to the Functions of LarkView Products",
    MENU_001: 'Basic Data Services',
    MENU_002: 'Contingency Management',
    MENU_003: 'Urban Architecture',
    MENU_004: 'Water Conservancy Facilities',
    MENU_005: 'Mine Safety',
    MENU_006: 'Agricultural Remote Sensing',
    MENU_007: 'Integrated Remote Sensing',
    MENU_008: 'Special Events',
    MENU_009: 'A map of radar information knowledge'
  },
  L_ABOUT: {
    BANNER_0001: "About Us",
    BANNER_0002: "We secure tomorrow",
    NAV_0001: "Company",
    NAV_0002: "Contact Us",
    CONTENT_0001:
      "VASTITUDE is the leading facility health diagnostic platform company based on spatial and temporal big data technology.",
    CONTENT_0002:
      'As the leader of domestic radar remote sensing technology application, Vastitude takes "We secure tomorrow" as its mission and "Earth Health bid data specialist" as its vision. It always focuses on urban and rural security governance and facility health diagnosis and treatment, relying on remote sensing and cloud computing. High-tech, Internet of Things, big data, artificial intelligence, etc., providing high-efficiency, low-cost, near-real-time integrated solutions for pre-warning prevention, scientific emergency, and after-the-fact rescue guidance for urban and rural geological and facility risk disasters, providing scientific, efficient and timely decision support for the government, providing professional, comprehensive and cost-effective comprehensive services for the industry.',
    CONTENT_0003:
      "Vastitude has created an engineering application model for remote sensing, InSAR and SAR technology in the field of public safety, and launched the world's first cloud service platform based on InSAR technology, “Vastitude Cloud”，with the nation's largest professional processing analysis and application service team for InSAR and SAR technology, it has the world's top radar satellite data processing algorithms, and has built a global high-precision radar satellite database and the world's largest Chinese urban health big data analysis library.Vastitude is supported by 21 world-class radar and optical satellite resources and self-established satellite ground receiving stations. Currently, it has achieved “physical examination, diagnosis and treatment guidance” from the global surface to the single infrastructure, with time and space big data. The core of the global health industry chain is constantly improving and steadily forming.",
    CONTENT_0004: "Attitude makes Vastitude",
    CONTENT_0005: "Contact Information",
    CONTENT_0006:
      "Address：East side of the second floor, Block A, Huanxing Building, No.14 Huayuan North Road, Haidian District, Beijing",
    CONTENT_0007: "Postcode：100091",
    CONTENT_0008: "Phone number：400 699 1223, +86-10-88511338",
    CONTENT_0009: "Fax：+86-10-88511358",
    CONTENT_0010: "E-mail：support@vastitude.cn",
    CONTENT_0011: "WeChat subscription：",
  },
  L_DOC: {
    TITLE: "Guide",
    NAV_0001: "InSAR Database Service",
    NAV_0002: "Building Deformation Analysis",
    NAV_0003: "Early Warning of Geological Hazards",
    NAV_0004: "Building Deformation Risk",
    CONTENT_0001: "InSAR Database Service Guide",
    CONTENT_0002:
      "InSAR data service is used to publish the InSAR monitoring results in key cities. The platform has more than seven years of accumulation of satellite data; supports viewing and browsing the distribution of InSAR data in various provinces and cities throughout the country, and provides on-line deformation analysis function, which can quickly realize buildings, subway, roads, bridges, high-speed rail, power, water conservancy and energy,risk screening, risk analysis and risk prevention of engineering facilities such as source.",
    CONTENT_0003: "一 Log In",
    CONTENT_0004:
      "1. Access web pages with Google Chrome, Firefox or IE10 and above: ",
    CONTENT_0005: "https://www.larkview.cn/#/insar",
    CONTENT_0006: ". ",
    CONTENT_0007:
      "2、Click the [Log In] button in the upper right corner of the page, enter the user name and password in the pop-up login prompt box, and click [Log In] to complete the login.",
    CONTENT_0008:
      "3、Click the [Start Now] button on the page to enter the Data InSAR Database Service page.",
    CONTENT_0009: "二、InSAR Database Service",
    CONTENT_0010:
      "1、Data source: displays the list of city data in the current login user's permission.",
    CONTENT_0010_01:
      "Click the [Data Source] drop-down box to view the authorized data items and basic information of the logged in user.",
    CONTENT_0010_02:
      "Click the checkbox in the list to select multiple data sources.",
    CONTENT_0010_03:
      "Select the data source to be viewed. The default distribution of the selected data source is displayed in PS point on the map.",
    CONTENT_0011:
      "2、Legend: Display the ribbon information of the selected data source.",
    CONTENT_0012:
      "3、Deformation Contour: Shows the distribution of data in isoline form.",
    CONTENT_0012_01:
      "Click to select the [Deformation Contour] button to display the deformation distribution on the map in isoline form.",
    CONTENT_0013:
      "4、Data Distribution: View the distribution of city InSAR data.",
    CONTENT_0013_01:
      "Click the [Data Distribution] button to mark the city with the existing data on the map.",
    CONTENT_0014:
      "5、Clear: Clear all analysis points, line segments and measurement marks on the map.",
    CONTENT_0015:
      "6、Transparency: Set the transparency of the PS point or contour layer on the map.",
    CONTENT_0055:
      "7、Legend Settings: Custom settings for ribbon information, including ribbon range and color.",
    CONTENT_0055_01:
      "Set the ribbon range and click the [Application] button to update the PS points or contours on the map.",
    CONTENT_0055_02:
      "Select any color icon and click the delete button to delete the color.",
    CONTENT_0055_03:
      "Select any color icon, click the color setting button on the right or double-click the color icon to modify the selected color.",
    CONTENT_0055_04:
      "Click on the area between any two colors to create a new color.",
    CONTENT_0055_05:
      "Drag the color icon to another color icon to modify the position of the two colors.",
    CONTENT_0055_06:
      "Select any recommended color, click the [Applicatio] button, and the PS point or contour on the map will be updated.",
    CONTENT_0055_07:
      "Click the [Reset] button to restore the initial settings.",
    CONTENT_0016: "三、InSAR data analysis",
    CONTENT_0017:
      "1、Time series analysis: display the deformation time series curve of the selected point or polygon.",
    CONTENT_0017_01:
      "Click on the 【Time Series Analysis】to support point and surface selection.",
    CONTENT_0017_02: "Analysis point：",
    CONTENT_0017_02_01:
      "a）Click the 【Point】 button and select the point on the map that needs to be analyzed.",
    CONTENT_0017_02_02:
      "b) The pop-up deformation time analysis window displays the deformation value curve of the selected analysis point.",
    CONTENT_0017_02_03:
      "c) Click the 【New】 button. Multiple analysis points can be selected on the map. Cancel the 【New】button. Only one analysis point can be selected on the map.",
    CONTENT_0017_03: "Analysis polygon：",
    CONTENT_0017_03_01:
      "a）Click the 【Polygon】 button, select the polygon that needs to be analyzed on the map, and double-click to finish drawing.",
    CONTENT_0017_03_02:
      "b) the deformation time series analysis window is displayed to show the deformation value curve.",
    CONTENT_0018:
      "2、Profile Analysis: Shows the deformation curve and time evolution of all analysis points on the selected line segment.",
    CONTENT_0018_01:
      "Click to select the [Profile Analysis] button, the mouse is to draw the line segment status.",
    CONTENT_0018_02:
      "Select the line segment you want to analyze on the map, double-click to finish drawing, and mark all analysis points.",
    CONTENT_0018_03:
      "The pop-up profile analysis window shows the average deformation velocity curve, cumulative deformation curve and time evolution of all analysis points on the selected line segment.",
    CONTENT_0019:
      "3、Regional Deformation Analysis: Find the settlement risk area of ​​the specified deformation range.",
    CONTENT_0019_01:
      "Click to select the [Regional Deformation Analysis] button to pop up the Regional deformation setting window.",
    CONTENT_0019_02:
      "Set the maximum deformation velocity of the analysis area.",
    CONTENT_0019_03:
      "All settlement risk areas found are displayed on the map.",
    CONTENT_0019_04:
      "Pop-up regional deformation analysis window showing data analysis list and data analysis chart.",
    CONTENT_0019_05:
      "Click the [Download Data Report] button in the regional deformation analysis window to download the data report.",
    CONTENT_0020:
      "4、Analysis Report: View the analysis report of the selected city online. ",
    CONTENT_0021: "四、Map Basic Function",
    CONTENT_0022: "1、Search: Find the location and mark it on the map.",
    CONTENT_0023:
      "2、Locate Your City: locate the city where the current user is located.",
    CONTENT_0024: "3、Zoom In: Zoom in on the map at a scale level.",
    CONTENT_0025: "4、Zoom Out: Reduce the map to a scale level.",
    CONTENT_0057:
      "5、Administrative Division: The administrative area boundary is displayed on the map.",
    CONTENT_0026: "6、Basemap Settings: switch the base map.",
    CONTENT_0027: "7、Measurement: Measure distance and area on the map.",
    CONTENT_0028:
      "8、Overview Map: View the location in the entire map, and support updating the map to display the content in the view window.",
    CONTENT_0029: "Building Deformation Analysis Guide",
    CONTENT_0030:
      "Through the building deformation analysis, the user can select or upload the AOI to the basic data input. Based on these information, the platform automatically combines the InSAR data results of the user's AOI to realize the online deformation analysis of the building, including deformation indicators and key point's deformation curves to help users understand the safety status in time.",
    CONTENT_0031: "一、Log In",
    CONTENT_0032:
      "1、Access web pages with Google Chrome, Firefox or IE10 and above：",
    CONTENT_0033: " https://www.larkview.cn/#/house",
    CONTENT_0034: ".",
    CONTENT_0035:
      "2、Click the [Log In] button , enter the user name and password in the pop-up login prompt box, and click [Log In] to complete the login.",
    CONTENT_0036:
      "3、Click the [Start now]button on the page to enter the building Deformation Analysis page.",
    CONTENT_0037: "二、Create Building Risk Analysis",
    CONTENT_0038:
      "1、Create building risk analysis: create the housing area that needs to be inquired.",
    CONTENT_0038_01:
      "Click to select the [Create Building Risk Analysis] button to pop up the new window.",
    CONTENT_0038_02:
      "Select the AOI of building: support rectangle selection, polygon selection and upload area file.",
    CONTENT_0038_03:
      "Set report type: Select the report type and set whether to download the report.",
    CONTENT_0038_04:
      "Set record name: Customize the name of the analysis record.",
    CONTENT_0038_05:
      "Click the submit button to display the submission window.",
    CONTENT_0039: "2、Submit window: prompt to create the result information.",
    CONTENT_0039_01:
      "Click the [Analysis Record] button to bring up the Analysis Record window.",
    CONTENT_0039_02:
      "Click the [Continue to Create] button to display the New Building Risk Analysis window.",
    CONTENT_0040:
      "3、Building classification: View the specific classification information of the building.",
    CONTENT_0040_01:
      "Click to select the [Building Classification] button, and the building classification mark and classification description information are displayed on the map.",
    CONTENT_0041: "三、View the Results of the Building Deformation Analysis",
    CONTENT_0042:
      "1、Analysis Record: Display the created house analysis record and house analysis result, including the query condition and analysis record list.",
    CONTENT_0042_01:
      "Click the [Analysis Record] button to bring up the Analysis Record window. ",
    CONTENT_0042_02:
      "Set the query conditions, the list shows the analysis records that match the query conditions.",
    CONTENT_0042_03:
      "Click the [Locate] button to locate the selected area on the map and display the area boundary.",
    CONTENT_0042_04:
      "Click the [View] button to display all the building in the selected recording area on the map and and color them to differentiate risk levels. ",
    CONTENT_0042_05:
      "Click the [Reference Point] button to display the reference point of the selected record on the map.",
    CONTENT_0042_06:
      "Click the [Download] button to download the analysis report.",
    CONTENT_0042_07:
      "Click the [Delete] button to delete the selected record. ",
    CONTENT_0043:
      "2、Analysis record details: display the created building analysis record results, including search and building list.",
    CONTENT_0043_01:
      "Click the expand button of the analysis record to display the details of the selected record.",
    CONTENT_0043_02:
      "Set the query conditions, the list shows the building that meet the query conditions.",
    CONTENT_0043_03:
      "Click on a buidling in the list, mark the selected house on the map, and pop up the building deformation information window.",
    CONTENT_0043_04:
      "Check the box in front of the building and click the Download Selection button to download the analysis report.",
    CONTENT_0044:
      "3、Building deformation information: Display the key point's deformation value curve and deformation index of the selected house.",
    CONTENT_0045:
      "Select a building on the analysis record details or map, and pop up the building deformation information window.",
    CONTENT_0046:
      "4、Statistical Report: View the building information statistics report of the selected city online.",
    CONTENT_0047: "四、Map Basic Function",
    CONTENT_0048: "1、Search: Find the location and mark it on the map.",
    CONTENT_0049:
      "2、Locate Your City: locate the city where the current user is located.",
    CONTENT_0050: "3、Zoom In: Zoom in on the map at a scale level.",
    CONTENT_0051: "4、Zoom Out: Reduce the map to a scale level.",
    CONTENT_0052: "5、Basemap Settings: switch the base map.",
    CONTENT_0053: "6、Measurement: Measure distance and area on the map.",
    CONTENT_0054:
      "7、Overview Map: View the location in the entire map, and support updating the map to display the content in the view window.",
    CONTENT_0064:
      "Through the platform of geological hazard hidden danger identification and monitoring and early warning, users can quickly screen geological hazard information and identify hidden dangers in corresponding areas；Choose the corresponding area for star monitoring, including InSAR monitoring and ground monitoring, meteorological monitoring, etc. At the same time, users can view the corresponding geological disaster forecast and early warning information; check the regional address disaster statistical analysis.",
  },
  L_ERROR: {
    TITLE: "Error",
    ERROR_0001: "You have no login or login timeout！",
    ERROR_0002: " Automatically jump to the login page",
    ERROR_0003: "Login Now",
    ERROR_0004: "Back to Home",
    ERROR_0005: "You do not have permission to access this page！",
    ERROR_0006: "Please contact the administrator",
  },
  L_LOGIN: {
    LOGIN_TITLE: "Welcome to Login",
    LOGIN_0001: "Enter your username",
    LOGIN_0002: "Enter your password",
    LOGIN_0003: "Enter the verification code",
    LOGIN_0004: "Vague, click for another",
    LOGIN_0005: "Enter your phone number",
    LOGIN_0006: "Login successful",
    LOGIN_0007: "Login failed",
    BUTTON_0001: "Sign In",
    BUTTON_0002: "Sign Up Now",
    BUTTON_0003: "Sign In by phone number",
    BUTTON_0004: "Sign In by password",
    BUTTON_0005: "verification code",
  },
  L_PRODUCT: {
    TITLE_0001: "InSAR Database Service",
    TITLE_0002: "Building Deformation Analysis",
    CONTENT_0001:
      "Support deformation risk online identification for engineering structures and online browsing of InSAR result for major cities in China ，provide access to deformation analysis report and rapid risk screening, analysis and prevention guidance of buildings, subway, roads, high-speed railway, electric power, water conservancy, energy, and other engineering facilities.",
    CONTENT_0002: "Function",
    CONTENT_0003_1: "Deformation Distribution Overview",
    CONTENT_0003_2: "Time Series Deformation Analysis",
    CONTENT_0003_3: "Profile Analysis",
    CONTENT_0003_4: "Risk Area Screening",
    CONTENT_0004: "Benefits",
    CONTENT_0005: "Abundant Data",
    CONTENT_0006:
      "Providing monitoring data for 7-12 years in large and medium-sized cities for users to choose",
    CONTENT_0007: "Dynamic Monitoring",
    CONTENT_0008:
      "Dynamic observation of temporal and spatial variations of surface environment based on, multi-platform and multi-source data",
    CONTENT_0009: "Visualization",
    CONTENT_0010:
      "Fast, intuitive and comprehensive presentation of the overall temporal and spatial trend of the city",
    CONTENT_0011: "Data and Analysis",
    CONTENT_0012:
      "Providing a customized urban facility in-depth analysis service",
    CONTENT_0013: "Application",
    CONTENT_0014: "Geological Disaster Prevention",
    CONTENT_0015: "Urban Settlement Assessment",
    CONTENT_0016: "Municipal Engineering Maintenance",
    CONTENT_0017: "Rail Transit Operation",
    CONTENT_0018: "Power Transmission Guarantee",
    CONTENT_0019: "Energy Facility Inspection",
    CONTENT_1001:
      "Through further excavation of the monitoring results of InSAR and analysis of the deformation information of each building, it is found that there are buildings with potential risk in the city, which can help users understand the safety status of the building in time, and provide data support for the safety management of the building design, construction, operation and maintenance stages.",
    CONTENT_1002: "Create Object on Demand",
    CONTENT_1003: "Keypoint Curve Analysis",
    CONTENT_1003_1: "Deformation Index Statistics",
    CONTENT_1003_2: "Analysis Report Download",
    CONTENT_1004: "Flexible and Diverse Use",
    CONTENT_1005:
      "Provides a variety of data input methods such as selectable, uploadable, etc",
    CONTENT_1006: "Intelligent risk screening",
    CONTENT_1007:
      "Automatically analyze the deformation index of the building with a professional algorithm",
    CONTENT_1008: "One-Click Report Generation",
    CONTENT_1009:
      "Providing a multi-level deformation risk analysis report for a single building",
    CONTENT_1010: "Construction Process Monitoring",
    CONTENT_1011: "Building Maintenance",
    CONTENT_1012: "Old House Check",
    CONTENT_1013: "Commodity Residential Insurance",
    CONTENT_1014: "Office Security Management",
    CONTENT_1015: "Stadium Hidden Danger Investigation",
    BUTTON_0001: "Try Now",
    BUTTON_0002: "Start Now",
    BUTTON_0003: "You do not have rights to access！",
    BUTTON_0004:
      "You do not have rights to access. For permission, please contact administrator！",
  },
  L_PROTOCOL: {
    TITLE: "Vastitude Cloud Service Platform Agreement",
    CONTENT_0001:
      'The usage agreement of Vastitude Cloud Service Platform (hereinafter referred to as "Vastitude Cloud") is a contract between you and Beijing Vastitude Technology Co., Ltd. (hereinafter referred to as "Vastitude Technology") on the terms of the registration usage agreement for the use of Cloud Service. Please read it carefully, when you visit, browse or use Vastitude technology products and any services indicate that you have read, understood and agreed to be bound by this clause. ',
    CONTENT_0002:
      "Dear Vastitude Cloud users, you are welcome to apply to become a registered user of Vastitude Cloud products, we are committed to providing you with one-stop service! If you get the Larkview product account, you can use the same set of usernames and passwords to access the authorized Vastitude technology products, such as InSAR data service, house deformation analysis, etc., after opening the rights of the corresponding product line.",
    CONTENT_0003: "Registration information",
    CONTENT_0004:
      "based on the particularity of the network and technology services provided by Vastitude Cloud, you should agree:",
    CONTENT_0005:
      "(1) provides detailed and accurate registration information and information.",
    CONTENT_0006:
      "(2) continuously updates registration information, which meets timely, detailed and accurate requirements. If the information you provide contains incorrect information, the Vastitude Cloud reserves the right to terminate your eligibility to use the network service. ",
    CONTENT_0007: "Login steps",
    CONTENT_0008:
      "To log in to Yuanyun products, please follow the steps below",
    CONTENT_0009:
      " (1) In the Username field, enter your username for the Vastitude Cloud;",
    CONTENT_0010: "（2）In the password field, enter your password;",
    CONTENT_0011:
      " (3) In the verification code field, enter the graphic verification code;",
    CONTENT_0012: " (4) Click Login.",
    CONTENT_0013: "Legal Notice",
    CONTENT_0014:
      'Vastitude Cloud reminds you：before using the services of larkview.cn, please read this statement carefully and understand it thoroughly. You may choose not to use the larkview.cn service, but if you choose to use the larkview.cn service, your usage behavior will be deemed to be an endorsement of the full content of this declaration. "larkview.cn" ”refers to the website operated by Beijing Vastitude  Technology Co., Ltd. (abbreviated as "Vastitude Technology"). The domain name is: www.larkview.cn.',
    CONTENT_0015:
      "larkview.cn Information about the registration of larkview.cn or its release (including, but not limited to, user name, company name, contact information, related pictures, videos, etc.) is provided by members themselves. Members shall be fully responsible for any information they provide in accordance with the law.",
    CONTENT_0016:
      "Any unit or individual believes that the contents of the larkview.cn webpage (including but not limited to the product information published by the members of larkview.cn) may be suspected of infringing on their legal rights and interests, and should promptly submit a written right notice to Vastitude Technology, and provide proof of identity and ownership certificate. , specific link (URL) and detailed proof of infringement. Upon receipt of the above legal documents, Vastitude Technology will remove relevant allegedly infringing content as soon as possible.",
    CONTENT_0017:
      "Larview.cn reprinted works (including forum content) for the purpose of transmitting more information does not mean that the Vastitude Cloud (including the distant cloud affiliates) agrees with its views or confirms the authenticity of its content. ",
    CONTENT_0018: "Intellectual Property Statement",
    CONTENT_0019:
      "Vastitude Technologies owns the copyright of all information content (including, but not limited to, text, pictures, software, audio, video) on the larkview.cn website, in addition to information published by members of larkview.cn.",
    CONTENT_0020:
      "Any authorized browsing, copying, printing and distribution of information on the website of larkview.cn shall not be used for commercial purposes and the use of all information and any part thereof must include this copyright notice;",
    CONTENT_0021:
      'larkview.cn all products, technologies and all analysis reports belong to Vastitude intellectual property rights. "Vastitude Cloud Service Platform", "larkview.cn", "other products and services of Vastitude Cloud name and related graphics, logo, etc. are registered trademarks of Vastitude Technology. Without the permission of Beijing Vastitude Technology Co., Ltd., no one shall use it without authorization (including, but not limited to, copying, disseminating, displaying, mirroring, uploading and downloading in an illegal manner). Otherwise,Vastitude will pursue legal responsibility according to law.',
    CONTENT_0022: "Privacy policy,",
    CONTENT_0023:
      "Vastitude technology respects and protects the privacy of all users using larkview.cn services. In order to provide you with more accurate and personalized services, Vastitude will use and disclose your personal information in accordance with the privacy policy and treat this information with a high degree of diligence and prudence. Except as otherwise stipulated in this Privacy Policy, Vastitude Cloud will not disclose or provide such information to a third party without your prior permission. Beijing Vastitdue technology will update this privacy policy from time to time. When you agree to the  cloud service agreement, it is deemed that you have agreed to the full content of this privacy policy. This privacy policy is an integral part of the Vastitude cloud service agreement",
    CONTENT_0024: "1. Scope",
    CONTENT_0025:
      "（1）When you register to Vastitude Cloud account, you are required to provide personal or organization registration information according to the requirements of Larkview;",
    CONTENT_0026:
      "（2）When you use the larkview.cn service or visit the larkview.cn webpage, the information on your browser and computer is automatically received and recorded by it, including but not limited to your IP address, browsing Type of device, language used, date and time of access, hardware and software feature information, and web page records you need;",
    CONTENT_0027:
      " (3) Larkview Cloud obtains user personal data from business partners through legitimate channels",
    CONTENT_0028:
      "you understand and agree that the following information does not apply to this Privacy Policy",
    CONTENT_0029: "（1）the information you release when you use larkview.cn；",
    CONTENT_0030:
      "（2）the information you publish when you use the services provided by larkview.cn；",
    CONTENT_0031:
      "（3）Credit evaluation, violation of legal provisions or the rules of the Vastitude Cloud, and the measures that Vastitude Technology has taken against you.",
    CONTENT_0032: "2. Information usage",
    CONTENT_0033:
      "（1） Vastitude Technology will not provide, sell, rent, share or trade your personal information to any unrelated third party unless you have obtained your permission in advance, or the third party and Vastitude Technology alone or jointly The service is provided, and after the service is terminated, it will be barred from accessing all such material that it has previously been able to access",
    CONTENT_0034:
      "（2）Vastitude Technology also does not allow any third party to collect, edit, sell or distribute your personal information by any means. Any user of larkview.cn who engages in the above activities, once discovered, Vastitude has the right to immediately terminate the service agreement with the user",
    CONTENT_0035:
      "（3）For the purpose of serving users, Vastitude may provide you with information of interest by using your personal information, including but not limited to sending you product and service information, or sharing information with partners so that they can send you information about their products and services (later) You need your prior consent.",
    CONTENT_0036: "3.  information disclosure",
    CONTENT_0037:
      "In the following circumstances, Zhiyuan Technology will disclose your personal information in whole or in part according to your personal wishes or the law：",
    CONTENT_0038: "（1）Disclose to third parties with your prior consent；",
    CONTENT_0039:
      "（2）If you are a suitable intellectual property complainant and have filed a complaint, it should be requested by the complainant and disclosed to the respondent so that both parties can handle possible rights disputes;",
    CONTENT_0040:
      "（3）Disclosure to third parties or administrative or judicial authorities in accordance with the relevant provisions of the law or the requirements of the administrative or judicial authorities;",
    CONTENT_0041:
      "（4）If you violate the relevant laws and regulations of China or the Vastitude technology service agreement or related rules, you need to disclose it to a third party；",
    CONTENT_0042:
      "（5）To provide the products and services you require, you must share your personal information with a third party；",
    CONTENT_0043:
      "（6）In a transaction created on larkview.cn, if either party fulfills or partially fulfills the transaction obligation and makes a request for information disclosure, Vastitude has the right to decide to provide the user with the necessary information such as the way in which the other party contacts the transaction in order to facilitate the completion of the transaction or the settlement of disputes",
    CONTENT_0044:
      "（7）Other disclosures that Vastitude Technology considers appropriate in accordance with laws, regulations or website policies",
    CONTENT_0045: "4. Information storage and exchange",
    CONTENT_0046:
      "The information and materials collected by Vastitude Cloud will be stored on the servers of Larkview or its affiliates. These information may be transmitted to your country, region or to the outside of the Cloud where the information and data are collected and accessed, stored and displayed abroad.",
    CONTENT_0047: "5.  Use of cookies",
    CONTENT_0048:
      "（1）In the event that you have not refused to accept cookies, Vastitude Cloud will set or access cookies on your computer so that you can log in or use the larkview.cn services or features that rely on cookies. The use of cookies by Vastitude Cloud can provide you with more thoughtful and personalized services, including promotion services.",
    CONTENT_0049:
      "（2） you have the right to choose to accept or reject cookies. You can refuse to accept cookies by modifying browser settings. But if you choose to reject cookies, you may not be able to log in or use the larkview.cn service or function that depends on cookies.",
    CONTENT_0050:
      "（3）This policy will apply to relevant information obtained from cookies of Vastitude Cloud；",
    CONTENT_0051: "6. Information security",
    CONTENT_0052:
      "（1）Vastitude Cloud will ensure that your information is not lost, not abused or altered by security measures such as backing up to other servers and encrypting user passwords. Despite the aforementioned security measures, please also note that there are no absolutely complete security measures on the information network.",
    CONTENT_0053:
      "（2）When using the larkview.cn service for online transactions, you will inevitably disclose your personal information, such as contact information or postal address, to the counterparty or potential counterparty. Please protect your personal information and provide it to others only when necessary. If you find that your personal information is leaked, especially if the Vastitude Cloud account and password are leaked, please contact the Vastitude technology customer service immediately, so that they can take corresponding measures.",
    CONTENT_0054:
      "（3）Disclosure to third parties or administrative or judicial authorities in accordance with the relevant provisions of the law or the requirements of the administrative or judicial authorities；",
    CONTENT_0055: "Disclaimer",
    CONTENT_0056:
      "The data and information provided by Vastitude Technology are for user's reference only and derived from legal channels and are as reliable, accurate and complete as possible, in accordance with international and industry-wide guidelines, but do not guarantee the comprehensiveness and timeliness of the information described in the report.The data and information provided can not be used as the final basis for the decision-making of Engineering exploration, nor as the moral, liability and legal basis or evidence, whether or not it has been made clear or implied.",
    CONTENT_0057:
      "The data and information provided by Vastitude Technology are owned by Beijing Vastitude Technology Co., Ltd. Any organization or individual may not reproduce, copy and distribute in any form without written permission. If the quotation is issued, the company's consent must be obtained in writing, and the source is Beijing Vastitude Technology Co., Ltd., and the report shall not be quoted, abridged or amended contrary to its original intention",
    CONTENT_0058:
      "Beijing Vastitude Technologies Co.,Ltd has the right to interpret this provision.",
    CONTENT_0059:
      "The company will try its best to provide all possible technologies and equipment to maintain the user's data and account security, and strive to provide you with the best service. However, Vastitude Technology is exempt from the following losses：",
    CONTENT_0060:
      "（1）As for the loss of user data and cessation of service becasue of unintentional or irresistible reasons (including system maintenance and upgrade), Vastitude Technology does not assume compensation and other joint legal liabilities.",
    CONTENT_0061:
      "（2）It is not because of the intention or fault of Vastitude Technology that the product service of Vastitude Cloud has not been successfully registered., ",
    CONTENT_0062:
      "（3）You have suffered losses due to your or your agent's deliberate or negligent behavior ；",
    CONTENT_0063:
      "（4）Any impact on you due to system testing, upgrades, updates, and service sampling tests of the service system.",
    CONTENT_0064:
      "（5）Vastitude reserves the right to delete any content in your account that is harmful to the national security, infringing on the copyright or personal rights or other legitimate rights and interests of others, or against the public order.",
    CONTENT_0065: "Other",
    CONTENT_0066:
      "This agreement is inconsistent with the specific service agreement between you and Vastitude Cloud for each product. The specific service agreement shall prevail.",
  },
  L_REGISTER: {
    BANNER_0001: "Welcome to Register",
    BUTTON_0001: "Register",
    TIP_0001: "Existing account?",
    TIP_0002: "Login Now",
    TIP_0003:
      "4-20 characters contains numbers, letters, Chinese characters and underscores",
    TIP_0004: "Invalid username",
    TIP_0005: "This username has been used. You can:",
    TIP_0006: "1、Re-enter another one",
    TIP_0007: "2、Use the username",
    TIP_0008: "to login",
    TIP_0009: "1-40 characters",
    TIP_0010: "Invalid company name",
    TIP_0011: "2-30 characters",
    TIP_0012: "Invalid name",
    TIP_0013: "Select industry",
    TIP_0014: "Enter your phone number",
    TIP_0015: "Invalid phone number",
    TIP_0016: "The phone number has been registered",
    TIP_0017: "Enter your email",
    TIP_0018: "Invalid email",
    TIP_0019: "The email has been registered",
    TIP_0020: "Invalid password",
    TIP_0021: "6-20 characters contains numbers and letters",
    TIP_0022: "Confirm your password",
    TIP_0023: "Password is inconsistent as above",
    TIP_0024: "Vague，click to change another",
    TIP_0025: "Enter verification code",
    TIP_0026: " I accept ",
    TIP_0027: " Vastitude cloud service platform usage agreement",
    TIP_0028: "Please check the agreement",
    TIP_0029: "Registration failed, please try again later",
    TIP_0030: "Congratulations, registration is successful!Your initial login password is：",
    TIP_0031: "Please wait patiently for review!",
    FORM_0001: "Enter your username",
    FORM_0002: "Enter your company name",
    FORM_0003: "Enter your name",
    FORM_0004: "Enter your phone number",
    FORM_0005: "Enter your email",
    FORM_0006: "Enter your password",
    FORM_0007: "Confirm your password",
    FORM_0008: "Enter the verification code",
  },
  L_USER: {
    BANNER_0001: "User Info",
    TITLE_0001: "Basic Info",
    TITLE_0002: "Change Password",
    BUTTON_0001: "Save",
    BUTTON_0002: "Cancel",
    FORM_0001: "Username：",
    FORM_0002: "Password：",
    FORM_0003: "Company：",
    FORM_0004: "Name：",
    FORM_0005: "Phone：",
    FORM_0006: "E-mail：",
    FORM_0007: "Old Password：",
    FORM_0008: "New Password：",
    FORM_0009: "Comfirm Password：",
    FORM_0010: "Verification Code：",
    TIP_0001: "Enter your password",
    TIP_0002: "6-20 character contains numbers and letters",
    TIP_0003: "Invalid password",
    TIP_0004: "Enter your company name",
    TIP_0005: "At least 4 Chinese characters",
    TIP_0006: "Invalid company name",
    TIP_0007: "Enter your name",
    TIP_0008: "2-30 characters contains English, Chinese characters, numbers",
    TIP_0009: "Invalid name",
    TIP_0010: "Enter your mobile number or landline number",
    TIP_0011: "Enter a mobile phone number or landline number",
    TIP_0012: "Invalid phone",
    TIP_0013: "Enter your email",
    TIP_0014: "Enter the email",
    TIP_0015: "Invalid email",
    TIP_0016: "Enter your old password",
    TIP_0017: "6-20 character contains numbers and letters",
    TIP_0018: "Invalid password",
    TIP_0019: "Enter your new password",
    TIP_0020: "6-20 character contains numbers and letters",
    TIP_0021: "Invalid password",
    TIP_0022: "Confirm your new password",
    TIP_0023: "Confirm your password",
    TIP_0024: "Invalid password",
    TIP_0025: "Enter the verification code",
    TIP_0026: "Vague, click for another",
    TIP_0027: "Enter verification code",
  },
  L_INSAR: {
    TITLE: "InSAR Database Service",
    MENU_0001: "My Data",
    MENU_0002: "Search",
    BUTTON_0001: "Time Series Analysis",
    BUTTON_0002: "Analysis Report",
    BUTTON_0003: "Regional Deformation Analysis",
    BUTTON_0004: "Clear",
    BUTTON_0005: "Administrative Division",
    BUTTON_0006: "Profile Analysis",
    BUTTON_0007: "",
    BUTTON_0008: "Vel",
    BUTTON_0009: "OK",
    BUTTON_0010: "Data Distribution",
    BUTTON_0011: "Deformation Contour",
    BUTTON_0012: "Legend Settings",
    BUTTON_0013: "User Management",
    BUTTON_0014: "Set Transparency",
    BUTTON_0015: "Locate Your City",
    BUTTON_0016: "Zoom In",
    BUTTON_0017: "Zoom Out",
    BUTTON_0018: "Basemap Settings",
    BUTTON_0019: "Measurement",
    BUTTON_0020: "Overview Map",
    CONTENT_0001: "User：",
    CONTENT_0002: "Profile Analysis",
    CONTENT_0003: "0",
    CONTENT_0004: "100%",
    CONTENT_0005: "Time Series Analysis",
    CONTENT_0006: "Regional Deformation Analysis",
    CONTENT_0007: "Data Analysis List",
    CONTENT_0008: "Download",
    CONTENT_0009: "Data Report",
    CONTENT_0010: "Point",
    CONTENT_0011: "Polygon",
    CONTENT_0012: "No.",
    CONTENT_0013: "Area",
    CONTENT_0014: "PS Points",
    CONTENT_0015: "Area/PS Points",
    CONTENT_0016: "Max Vel",
    CONTENT_0017: "Data Analysis Chart",
    CONTENT_0018: "Average Deformation Velocity",
    CONTENT_0019: "Accumulative Deformation ",
    CONTENT_0020: "Temporal Evolution",
    CONTENT_0021: "No data",
    CONTENT_0022: "Basemap Settings",
    CONTENT_0023: "General",
    CONTENT_0024: "Satellites",
    CONTENT_0025: "Satellites     (Mixed)",
    CONTENT_0026: "Tarrain",
    CONTENT_0027: "Measurement",
    CONTENT_0028: "Distance",
    CONTENT_0029: "Area",
    CONTENT_0030: "Measurement results: ",
    CONTENT_0031: "Data Information",
    CONTENT_0032: "Satellites[band]：",
    CONTENT_0033: "Method：",
    CONTENT_0034: "Orbit direction：",
    CONTENT_0035: "Image scenes：",
    CONTENT_0036: "Start time：",
    CONTENT_0037: "End time：",
    CONTENT_0047: "User name",
    CONTENT_0048: "Exit",
    CONTENT_0049: "Please enter a funnel value!",
    CONTENT_0050: "No Data.",
    CONTENT_0051: "No Data.",
    CONTENT_0052: "Check no results",
    CONTENT_0053: "There is no data here!",
    CONTENT_0054: "No analysis report yet!",
    CONTENT_0055: "Total ",
    CONTENT_0056: "",
    CONTENT_0057: " to ",
    CONTENT_0058: "Scenes：",
    CONTENT_0059: "Provices",
    CONTENT_0060: "Original Deformation ",
    CONTENT_0061: "Trend Deformation ",
    CONTENT_0062: "Time",
    CONTENT_0063: "Data",
    CONTENT_0064:
      "Support up to 5 point queries. Are you sure you want to clear all the analysis points on the map？",
    CONTENT_0065: "Point interval ",
    CONTENT_0066: "m",
    CONTENT_0067: "km",
    CONTENT_0068: "m²",
    CONTENT_0069: "km²",
    CONTENT_0070: "Click",
    CONTENT_0071: "Double Click to End",
    CONTENT_0072: "Multi-point query",
    CONTENT_0073: "Single point query",
    CONTENT_0074: "Darwing area can not exceed 1 square kilometer!",
    CONTENT_0075: "Overall",
    CONTENT_0076: "Local",
    CONTENT_0077: "Reference Point:",
    CONTENT_0078: "At most support 5 data sources!",
    CONTENT_0079: " scenes",
    CONTENT_0080: "Reset",
    CONTENT_0081: "Application",
    CONTENT_0082:
      "Simultaneous operation of multiple data sources is not supported at this time!",
    PAGE_0001: "First",
    PAGE_0002: "Last",
    PAGE_0003: "Refresh",
    PAGE_0004: "<",
    PAGE_0005: ">",
  },
  L_HOUSE: {
    TITLE: "Building Deformation Analysis",
    BUTTON_0001: "Search",
    BUTTON_0002: "Create Building Risk Analysis",
    BUTTON_0003: "Analysis Record",
    BUTTON_0004: "PS Points Range",
    BUTTON_0005: "Clear",
    BUTTON_0006: "User Management",
    BUTTON_0007: "Delete",
    BUTTON_0008: "Rectangle Selection",
    BUTTON_0009: "Polygon Selection",
    BUTTON_0010: "Upload AOI File",
    BUTTON_0011: "Yes",
    BUTTON_0012: "No",
    BUTTON_0013: "OK",
    BUTTON_0014: "Cancel",
    BUTTON_0015: "View Analysis Record",
    BUTTON_0016: "Analysis Record",
    BUTTON_0017: "Close",
    BUTTON_0018: "Query",
    BUTTON_0019: "Reset",
    BUTTON_0020: "New",
    BUTTON_0021: "Locate Your City",
    BUTTON_0022: "Zoom In",
    BUTTON_0023: "Zoom Out",
    BUTTON_0024: "Basemap Settings",
    BUTTON_0025: "Measurement",
    BUTTON_0026: "Overview Map",
    BUTTON_0027: "General",
    BUTTON_0028: "Satellite",
    BUTTON_0029: "Satellite (Mixed)",
    BUTTON_0030: "Terrain",
    BUTTON_0031: "Distance",
    BUTTON_0032: "Area",
    BUTTON_0033: "Measurement result: ",
    BUTTON_0034: "User name",
    BUTTON_0035: "Exit",
    BUTTON_0036: "Statistical Report",
    BUTTON_0037: "Detection Result",
    CONTENT_0001: "Create New Window",
    CONTENT_0002: "Select the AOI of Building",
    CONTENT_0003: "Select the rectangular area on the map after selecting it",
    CONTENT_0004: "Select a polygon area on the map after selection",
    CONTENT_0005: "Select .shp, .shx, .dbf files in three formats",
    CONTENT_0006:
      "Please drag and drop files of .shp, .shx, .dbf into this area",
    CONTENT_0007: "Set Report Information",
    CONTENT_0008: "Report Type：",
    CONTENT_0009: "Building deformation report",
    CONTENT_0010: "Download Report：",
    CONTENT_0011: "New building deformation analysis",
    CONTENT_0012: "Submitting, please be patient...",
    CONTENT_0013: "Record",
    CONTENT_0014: "Duration:",
    CONTENT_0015: "Select start time",
    CONTENT_0016: "Select end time",
    CONTENT_0017: "To",
    CONTENT_0018: "Status:",
    CONTENT_0019: "Original Deformation ",
    CONTENT_0020: "Trend Deformation ",
    CONTENT_0021: "Deformation Index",
    CONTENT_0022: "Set Record Name",
    CONTENT_0023: "Record Name：",
    CONTENT_0024: "Continue",
    CONTENT_0025: "Statistical Report",
    CONTENT_0026: "Search for city name",
    CONTENT_0027: "Number",
    CONTENT_0028: "City name",
    CONTENT_0029: "Operation",
    CONTENT_0030: "User：",
    CONTENT_0031: "Deformation curve",
    CONTENT_0032: "Tilt curve",
    CONTENT_0033: "Prev",
    CONTENT_0034: "Next",
    CONTENT_0035: "Hangzhou",
    CONTENT_0036: "Check no results",
    CONTENT_0037: "Submitting, please wait for...",
    CONTENT_0038: "Please select a report type",
    CONTENT_0039: "Select the review status",
    CONTENT_0040: "Please select the building area!",
    CONTENT_0041: "Please select the area with PS points to draw",
    CONTENT_0042: "Drawing area can't exceed 2 square kilometers",
    CONTENT_0043: "Please choose whether to download the report!",
    CONTENT_0044:
      "Unfortunately, there is no PS point in the area you selected! ",
    CONTENT_0045:
      "Please click [Analysis Record] to view the building analysis information",
    CONTENT_0046: "Congratulations!",
    CONTENT_0047:
      " PS points. Please click [Record] to check building analysis results.",
    CONTENT_0048: "The operation failed, please try again later!",
    CONTENT_0049:
      "Please click [Record] to view the house analysis information. ",
    CONTENT_0050: "Are you sure to delete?",
    CONTENT_0051: "Please select three valid files！",
    CONTENT_0052: "The file size does not exceed 1M !",
    CONTENT_0053: "File upload failed!",
    CONTENT_0054: "Time",
    CONTENT_0055: "Status",
    CONTENT_0056: "Acq Period",
    CONTENT_0057: "PS Points",
    CONTENT_0058: "Action",
    CONTENT_0059: "AOI",
    CONTENT_0060: "View",
    CONTENT_0061: "",
    CONTENT_0062: "",
    CONTENT_0063: "No.",
    CONTENT_0064: "Check no data!",
    CONTENT_0065: "Download",
    CONTENT_0066: "Key Point ",
    CONTENT_0067: "Date",
    CONTENT_0068: "Vel/mm",
    CONTENT_0069: "Building code：",
    CONTENT_0070: "Assessment level：",
    CONTENT_0071: "Classification：",
    CONTENT_0072: "Overall cumulative deformation：",
    CONTENT_0073: "Cumulative tilt value：",
    CONTENT_0074: "The duration of the recent continuous deformation：",
    CONTENT_0075: "Recent continuous deformation vel：",
    CONTENT_0076: "The duration of recent continuous tilt value：",
    CONTENT_0077: "Recent continuous tilt deformation vel：",
    CONTENT_0078: "Building code：",
    CONTENT_0079: "Monitoring Period：",
    CONTENT_0080: " ：",
    CONTENT_0081: "No Data.",
    CONTENT_0082: "Tilt Value",
    CONTENT_0083: "No valid data for selected building, please select again.",
    CONTENT_0084: "Are you sure you want to delete all？",
    CONTENT_0085: "In total ",
    CONTENT_0086: "m",
    CONTENT_0087: "km",
    CONTENT_0088: "m²",
    CONTENT_0089: "km²",
    CONTENT_0090: "Select all",
    CONTENT_0091: "No valid data",
    CONTENT_0092: "Double Click to End",
    CONTENT_0093: "Building list",
    CONTENT_0094: "Search keyword",
    CONTENT_0095: "Select assessment level",
    CONTENT_0096: "Building Code",
    CONTENT_0097: "Building classification",
    CONTENT_0098: "Assessment Level",
    CONTENT_0099: "Download",
    CONTENT_0100: "Name",
    CONTENT_0101: "Reference Point",
    CONTENT_0102: "Delete",
    CONTENT_0103: "Are you sure to delete this order？",
    CONTENT_0104: "No analytical conditions are available.",
    CONTENT_0105: "Please select a valid building area.",
    CONTENT_0106: "year(s)",
    CONTENT_0107: ">6 month(s)",
    CONTENT_0108: " month(s)",
    CONTENT_0109: "mm/month",
    CONTENT_0110: "‰/month",
    CONTENT_0111: "Check Report",
    CONTENT_0112:
      "{label} failed：No data are currently selected or no valid data, please select again!",
    CONTENT_0113: "Please click [Record] to check building analysis results.",
    PAGE_0001: "First",
    PAGE_0002: "Last",
    PAGE_0003: "Refresh",
    PAGE_0004: "<",
    PAGE_0005: ">",
  },
  L_SYSTEM_TIP: {
    TIP_0001: "Prompt",
    TIP_0002: "OK",
    TIP_0003: "Yes",
    TIP_0004: "No",
  },
  L_DO: {
    APPLYSTATUS: "APPLYSTATUSEN",
    HOUSEDANGERGRADE: "HOUSEDANGERGRADEEN",
    INDUSTRY: "INDUSTRYEN",
    REPORTAPPLYSTATE: "REPORTAPPLYSTATEEN",
    REPORTTYPE: "REPORTTYPEEN",
  },
};
export default en;
