import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// i18n
import i18n from "./i18n/i18n"; // 把i18n单独放一个文件夹，这里是引用
import store from "./store";
// pc-element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// element-ui-verify
import elementUIVerify from "element-ui-verify";
Vue.use(elementUIVerify, {
  errorMessageTemplate: {
    empty: "{alias}不能为空",
    length: "请输入{length}位字符",
    minLength: "输入内容至少{minLength}位",
    maxLength: "输入内容最多{minLength}位",
    number: "请输入数字",
    int: "请输入整数",
    lt: "输入数字应小于{lt}",
    lte: "输入数字不能大于{lte}",
    gt: "输入数字应大于{gt}",
    gte: "输入数字不能小于{gte}",
    maxDecimalLength: "{alias}最多接受{maxDecimalLength}位小数",
    phone: "请输入正确的手机号",
    email: "请输入正确的邮箱",
    verifyCode: "请输入正确的验证码",
  },
  fieldChange: "clear",
});
// iconfont
import "./iconfont/iconfont.css";
import animated from "animate.css";
Vue.use(animated);
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);
// 项目变量配置
import projectConfig from "../src/config/projectConfig.js";
Vue.prototype.$projectConfig = projectConfig;
// 获取屏幕宽高
Vue.prototype.$getViewportSize = function () {
  return {
    width:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth, //兼容性获取屏幕宽度
    height:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight, //兼容性获取屏幕高度
  };
};
import "default-passive-events";
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
