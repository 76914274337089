import Cookies from "js-cookie";
import { setLocalStorage, getLocalStorage } from "@/utils/localstorage";
const getDefaultUserState = () => {
  return {
    language: "中文",
    token: getLocalStorage("lkplat-v2-token")
      ? getLocalStorage("lkplat-v2-token")
      : null,
    userInfo: getLocalStorage("larkview_userInfo")
      ? getLocalStorage("larkview_userInfo")
      : "",
    username: getLocalStorage("larkview_userName")
      ? getLocalStorage("larkview_userName")
      : "",
    isLogin: getLocalStorage("larkview_loginStatus")
      ? getLocalStorage("larkview_loginStatus")
      : false,
  };
};

let user = {
  state: getDefaultUserState(),
  mutations: {
    setLanguage: (state, language) => {
      state.language = language;
      Cookies.set("language", language);
    },
    setToken: (state, token) => {
      state.token = token;
      setLocalStorage("lkplat-v2-token", token, 24);
    },
    setUserInfo: (state, info) => {
      if (info === "{}") {
        state.userInfo = "";
      } else {
        state.userInfo = info;
      }
      setLocalStorage("larkview_userInfo", state.userInfo, 24);
      let str = JSON.stringify(state.userInfo);
      localStorage.setItem("userinfo", str);
      setLocalStorage("larkview_userInfo", state.userInfo, 24);
      if (state.userInfo !== "" && state.userInfo.username) {
        state.username = state.userInfo.username;
        setLocalStorage("larkview_userName", state.userInfo.username, 24);
        state.isLogin = true;
        setLocalStorage("larkview_loginStatus", state.isLogin, 24);
      } else {
        state.username = "";
        setLocalStorage("larkview_userName", state.userInfo.username, 24);
        state.isLogin = false;
        setLocalStorage("larkview_loginStatus", state.isLogin, 24);
      }
    },
  },
};
export default user;
