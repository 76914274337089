import Vue from "vue";
import Cookies from "js-cookie";
import i18n from "vue-i18n";
Vue.use(i18n);

import zh from "./langs/zh.js"; //中文
import en from "./langs/en.js"; //英文

/*---------使用语言包-----------*/
const messages = {
  //使用语言包
  zh,
  en,
};
// 切换语言
export function getLanguage() {
  const chooseLanguage = Cookies.get("language");
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return "en";
}
const vueI18n = new i18n({
  locale: getLanguage(), //语言标识
  messages: messages,
  silentFallbackWarn: true, //仅在根本没有可用的转换时生成警告，而不是在回退时
});

export default vueI18n; //导出
