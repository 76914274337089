<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    // 判断页面是否在手机端打开
    if (this._isMobile()) {
      window.location.href = "https://app.larkview.cn/beta/mobile/index.html";
    } else {
      console.log("当前在PC端");
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    beforeunloadHandler() {
      this.beforeunloadTime = new Date().getTime();
    },
    unload() {
      this.unloadTime = new Date().getTime();
      // 本地通过localStorage中的数据看出,关闭事件间隔小于1,刷新则大于8
      console.log(this.unloadTime - this.beforeunloadTime);
      if (this.unloadTime - this.beforeunloadTime <= 3) {
        // 执行退出登录
        this.$store.commit("setToken", null);
        this.$store.commit("setUserInfo", "");
        window.close();
      }
    },
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => {
      this.beforeunloadHandler();
    });
    window.addEventListener("unload", (e) => {
      this.unload();
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => {
      this.beforeunloadHandler(e);
    });
    window.removeEventListener("unload", (e) => {
      this.unload(e);
    });
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  min-width: 1200px;
}
#app .homeWrapper {
  width: 100%;
  height: 100%;
  min-width: 1200px;
}
</style>
